import { useEffect } from "react"

export default (ref, handler, events) => {
  if (!events) events = [`mousedown`, `touchstart`]

  let detectClickOutside
  if (Array.isArray(ref)) {
    detectClickOutside = event => {
      let found = false
      for (let i = 0; !found && i < ref.length; i++) {
        if (ref[i].current && ref[i].current.contains(event.target)) {
          found = true
        }
      }
      if (!found) {
        handler()
      }
    }
  } else {
    detectClickOutside = event =>
      ref.current && !ref.current.contains(event.target) && handler()
  }

  useEffect(() => {
    for (const event of events)
      document.addEventListener(event, detectClickOutside)
    return () => {
      for (const event of events)
        document.removeEventListener(event, detectClickOutside)
    }
  })
}