import React, { useState, useEffect, useRef } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import algoliasearch from "algoliasearch/lite"
import { PoweredBy } from "./styles"
import { Icon } from "react-icons-kit"
import { ic_close } from 'react-icons-kit/md/ic_close'
import { magnifying_glass } from 'react-icons-kit/ikons/magnifying_glass'

import Button from "common/src/components/Button"
import useDebounce from "../../components/useDebounce"
import useClickOutside from "../../components/useClickOutside"
import cancellable from "../../tools/promise-cancellable"

const Input = styled.input`
  width: ${props => (props.focused ? "100%" : "0px")};
  opacity: ${props => (props.focused ? "1" : "0")};
  outline: none;
  background-color: transparent;
  transition: width 0.5s ease-in-out;
  padding: 10px;
  font-size: 1.2em;
  font-weight: bold;
  border: none;
  width: 100%;
`

const SearchWrapper = styled.div`
  @media(max-width: 780px) {
    width: calc(100vw - 48px);
    max-width: calc(100vw - 48px);
    left: 24px;
    position: fixed;
  }
  display: flex;
  border: 2px solid #008abe;
  border-radius: 5px;
  max-width: 400px;
  width: 100%;
  background-color: white;
  z-index: 100;
`

const SearchResults = styled.div`
  max-width: calc(100vw - 100px);
  @media(max-width: 780px) {
    max-width: 100vw;
  }
  max-height: calc(100vh - 80px);
  border: 2px solid #008abe;
  border-radius: 5px;
  overflow-y: auto;
  top: ${props => props.top || "60px"};
  position: absolute;
  right: 15px;
  background-color: white;

  .algolia-docsearch-suggestion--highlight {
    background-color: red;
    color: white;
  }
`

const HitResult = styled.div`
  padding: 10px;
  width: 100%;
  display: inline-block;
  &:hover {
    background-color: #ecf5ff;
  }
  p {
    margin-bottom: 0px;
  }
`

const IconButton = styled(Button)`
  color: gray;
  &:hover {
    color: #008abe;
  }
`

const Hit = ({ value }) => {
  let breadcrumbs = ""
  const hierarchy = value?._highlightResult?.hierarchy || value.hierarchy
  for (let i = 0; i < 6; i++) {
    const lvl = hierarchy["lvl" + i]
    lvl && (breadcrumbs += " &rarr; " + lvl.value || lvl)
  }
  const url = new URL(value.url, "https://www.repairsetup.com")
  return <Link to={url.pathname + url.hash}>
    <HitResult>
      {breadcrumbs !== "" && <div dangerouslySetInnerHTML={{ __html: breadcrumbs }} />}
      {value?._snippetResult?.content && <p dangerouslySetInnerHTML={{ __html: value._snippetResult.content.value }} />}
    </HitResult>
  </Link>
}

export default function Search({ hitsTop, onToggle }) {
  const ref = useRef()
  const refWrap = useRef()
  const searchResultsRef = useRef()
  const [query, setQuery] = useState(``)
  const queryDebounced = useDebounce(query, 300)
  const [focus, setFocus] = useState(false)
  const [hitResult, setHitResult] = useState(null)
  const fetchRef = useRef()

  const algolia = algoliasearch(
    process.env.GATSBY_ALGOLIA_APP_ID,
    process.env.GATSBY_ALGOLIA_SEARCH_KEY
  ).initIndex(process.env.GATSBY_ALGOLIA_INDEX)

  useEffect(() => {
    const fetchData = async () => {
      // Ignore any inflight request
      if (fetchRef.current) {
        fetchRef.current.cancel()
      }
      if (queryDebounced !== "") {
        fetchRef.current = cancellable(algolia.search(queryDebounced))
        try {
          const hits = await fetchRef.current
          console.log(hits)
          setHitResult(hits)
        } catch (err) {
          console.log(err)
        }
      } else {
        setHitResult(null)
      }
    }
    fetchData()
  }, [queryDebounced])

  useEffect(() => {
    ref.current && ref.current.focus()
  }, [focus])

  const handleSearch = () => {
    setFocus(true)
    onToggle && onToggle(true)
  }
  const handleSearchInput = async event => {
    const query = event.target.value
    setQuery(query)
  }
  const handleSearchKey = event => {
    if (event.key === "Escape") {
      setFocus(false)
      onToggle && onToggle(false)
      ref.current.blur()
    }
  }
  const handleSearchClear = event => {
    if (query === "") {
      setFocus(false)
      onToggle && onToggle(false)
      ref.current.blur()
      return
    }
    ref.current.value = ""
    setQuery("")
    ref.current.focus()
  }
  useClickOutside([refWrap, searchResultsRef], () => {
    setFocus(false)
    onToggle && onToggle(false)
  })

  return <>
    {focus && <SearchWrapper ref={refWrap}>
      <Input ref={ref} value={query} focused={focus} placeholder="Search" onChange={handleSearchInput} onKeyDown={handleSearchKey} />
      <Button
        variant="textButton"
        onClick={handleSearchClear}
        icon={<div style={{ color: "red" }}><Icon icon={ic_close} size={32} /></div>}
        aria-label="clear"
      />
    </SearchWrapper>}
    {focus && hitResult && <SearchResults ref={searchResultsRef} top={hitsTop}>
      {hitResult.hits.length > 0 ?
        hitResult.hits.map((h, i) => <div key={i}>
          <Hit value={h} />
        </div>)
        :
        <p style={{ padding: 10, marginBottom: 0 }}>No search results found. Try searching another text.</p>
      }
      <PoweredBy />
    </SearchResults>}
    <IconButton
      variant="textButton"
      onClick={handleSearch}
      icon={<Icon icon={magnifying_glass} size={24} />}
      aria-label="search"
    />
  </>
}