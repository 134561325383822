import styled from 'styled-components';
import {
  width,
  height,
  color,
  space,
  border,
  boxShadow,
  borderRadius,
} from 'styled-system';

const HamburgMenuWrapper = styled.div`
  border: 0;
  background: transparent;
  width: 44px;
  height: 30px;
  cursor: pointer;
  ${width}
  ${height}
  ${color}
  ${space}
  ${border}
  ${boxShadow}
  ${borderRadius}

  > span {
    display: block;
    width: 100%;
    height: 2px;
    margin: 4px 0;
    float: right;
    background-color: ${props => (props.barColor ? props.barColor : '#10ac84')};
    transition: all 0.3s ease;
    transform-origin: 8px;
    &:first-child {
      transform: ${({ open }) => (open ? 'rotate(45deg)' : 'rotate(0)')};
      margin-top: 0;
    }
    &:nth-child(2) {
      transform: ${({ open }) => open ? 'translateX(20px)' : 'translateX(0)'};
      opacity: ${({ open }) => (open ? '0' : '1')};
    }
    &:last-child {
      width: ${({ open }) => (open ? '100%' : 'calc(100% - 10px)')};
      transform: ${({ open }) => (open ? 'rotate(-45deg)' : 'rotate(0)')};
      margin-bottom: 0;
    }
  }

  &:focus,
  &:hover {
    outline: none;
    > span {
      &:last-child {
        width: 100%;
      }
    }
  }
`;

HamburgMenuWrapper.displayName = 'HamburgMenuWrapper';

export default HamburgMenuWrapper;
